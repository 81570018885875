import {
  ApproachOption,
  ArticleImageGenerationSettings,
  ArticleTextGenerationSettings,
  AudienceOption,
  LanguageOption,
  PlatformOption,
  TextLengthOption,
  ToneOption
} from 'utils/types';

export const DEFAULT_AI_TEMPLATE_NAME = 'Topic Oriented';

export const SUGGESTIONS_TEMPLATE_NAME = 'Suggestions';
export const VENTI_ARTICLE_TEMPLATE_NAME = 'Venti Article';
export const MIND_FLOW_TEMPLATE_NAME = 'Mind Flow';
export const BYTE_SIZED_TEMPLATE_NAME = 'Byte-Sized';
export const SUMMARIZE_TEMPLATE_NAME = 'Summarize';

export const CREATE_TEXT_GENERATION_FIXED_CACHE_KEY = 'CREATE_TEXT_GENERATION_FIXED_CACHE_KEY';
export const DEFAULT_ARTICLE_TEXT_GENERATION_SETTINGS: Omit<ArticleTextGenerationSettings, 'templates'> = {
  temperature: 0.5,
  presence_penalty: 0.2,
  frequency_penalty: 0.8,

  language: 'en',
  output_language: 'en',
  fc_output_language: false,

  textLength: 'short',
  tone: 'Neutral',

  prompt_length: 200,
  num_samples: 3,
  returnKeywords: true
};

export const CREATE_IMAGE_GENERATION_FIXED_CACHE_KEY = 'CREATE_IMAGE_GENERATION_FIXED_CACHE_KEY';
export const DEFAULT_ARTICLE_IMAGE_GENERATION_SETTINGS: Omit<ArticleImageGenerationSettings, 'templates'> = {
  aestheticRating: 7,
  aestheticWeight: 0.5,
  guidanceScale: 10.5,
  numberOfImage: 1,
  promptStrength: 0.5,
  relevance: 10,
  seed: -1,
  trialSteps: 65
};

export const MAX_TOKEN_LENGTH: Record<TextLengthOption, number> = {
  short: 60,
  medium: 80,
  long: 100
};

export const TONE_OPTIONS: { label: string; value: ToneOption }[] = [
  { value: 'Neutral', label: 'Neutral' },
  { value: 'Friendly', label: 'Friendly' },
  { value: 'Professional', label: 'Professional' },
  { value: 'Witty', label: 'Witty' }
];
export const VENTI_ARTICLE_TONE_OPTIONS: { label: string; value: ToneOption }[] = [
  { value: 'Informative', label: 'Informative' }
];
export const MIND_FLOW_TONE_OPTIONS: { label: string; value: ToneOption }[] = [
  { value: 'Informative', label: 'Informative' },
  { value: 'Comparative', label: 'Comparative' },
  { value: 'Commentary', label: 'Commentary' },
  { value: 'Didactic', label: 'Didactic' }
];

export const AUDIENCE_OPTIONS: { label: string; value: AudienceOption }[] = [
  { value: 'teenagers', label: 'Teenagers' },
  { value: 'middle age', label: 'Middle Aged' },
  { value: 'elderly', label: 'Elderly' },
  { value: 'high end', label: 'High End' },
  { value: 'mass', label: 'Mass' }
];

export const PLATFORM_OPTIONS: { label: string; value: PlatformOption }[] = [
  { value: 'Twitter', label: 'Twitter' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' }
];

export const APPROACH_OPTIONS: { label: string; value: ApproachOption }[] = [
  { value: 'Third Person', label: 'Third Person' },
  { value: 'First Person', label: 'First Person' },
  { value: 'Documentary', label: 'Documentary' }
];

export const FACT_CHECK_ENDPOINT_WHITELIST = [
  'intro',
  'conclusion',
  'suggestions',
  'paraphrase',
  'mindflow',
  'suggestions-lm',
  'longform'
];

export const SUPPORTED_INPUT_OUTPUT_LANGS: { label: string; value: LanguageOption }[] = [
  { label: 'English', value: 'en' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Russian', value: 'ru' },
  { label: 'Spanish', value: 'es' },
  { label: 'Italian', value: 'it' },
  { label: 'Arabic', value: 'ar' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' }
];

export const PROOF_READ_INLINE_STYLE_MAP: Record<string, 'EXACT_MATCH' | 'SIMILAR_MATCH'> = {
  EXACT: 'EXACT_MATCH',
  SIMILAR: 'SIMILAR_MATCH'
};

export const UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY = 'UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY';

export const geoAPIBaseUrl = 'https://ipapi.co/json/';

export const TEMPLATE_ICONS = {
  Suggestions: '',
  Intro: '',
  '2nd Grader Summarization': '',
  'Third-person converter': '',
  'Ad Generation': '📺',
  'Blog Outline / Ideas': '',
  'Grammar correction': '🔤',
  Healthcare: '🏥',
  'Healthcare 2': '⛑️',
  'Create study notes': '',
  'Meeting Notes': '',
  'Paragraph Completion': '',
  Email: '',
  Summarize: '📚',
  Rewrite: '',
  'Byte-Sized': '',
  'Media Content Gen': '',
  'Venti Article': '📜',
  'Mind Flow': '',
  'Title Creator': '',
  Paraphrase: '📝',
  'Detailed Sugesstions': '💭',
  SEO: '',
  'Complete Blog Post': '',
  'SEO Meta Title': '',
  'Rephrase with Synonyms': '',
  'The Athletic - Lebron edition': '',
  'NYT Style': '',
  'Novus Writing Assistant': '',
  'Case Study': '🤓',
  'Elevator Pitch': '💥',
  'Real Estate Listing': '🏡',
  Commands: '⚡️',
  'Video Description': '🎥',
  'Video Titles': '',
  'Video Script': '📃',
  'Blog Outline & Ideas': '🗒',
  'Seo Article': '📑',
  'Seo UGC': '',
  'Seo Leadership': '📯',
  'Cold Email': '🥶',
  'Sales Sequence': '📈',
  'Google Ads Description': '🏷',
  'Google Ads Headline': '🪧',
  'Etsy Product Description': '🛍',
  'Instagram Market Post': '📱',
  'Easy Response': '😊',
  'Pain Point Generator': '📌',

  // Image template names
  Dalle2: '',
  'Free-Form': '',
  'Human included realistic photos': '',
  'Portrait of people': '',
  'Architecture & Landscape': '',
  'Comic Book Style': '',
  Scenery: '',
  'Material Photography': '',
  Fashion: '',
  Cyberpunk: '',
  'Asian Graphic Design': '',
  'Poster design': '',
  'Futuristic&Cyberpunk Style': '',
  'Animation&Manga': '',
  Drawing: '',
  Cinematic: '',
  'Oil Painting': '',
  'Social Media Post for Instagram': '',
  'Social Media Post for Twitter': '',
  'Social Media Post for Facebook': ''
};

export const TEMPLATE_PARENT_ICONS = {
  'E-commerce': '🛍',
  Communications: '📞',
  'Video Builder': '🎥',
  'Industrial Applications': '🏭',
  'Email Providers': '📧',
  'SEO Boosters': '📈',
  'Writing Tools': '📝',
  'Daily Use': '👨‍💻'
};
