import { RawDraftContentState } from 'draft-js';

export type DrawerSection = 'templates' | 'customTemplates' | 'beta' | 'settings';
export type AppSelectboxOption = { label: string; value: string };
export type DrawerSizeState = 'opened' | 'closed';
export type ImageType = 'png' | 'jpeg' | 'pdf';
export type ArticleFormType = 'text' | 'image';

export type TextLengthOption = 'short' | 'medium' | 'long';
export type LanguageOption = 'en' | 'tr' | 'ru' | 'es' | 'it' | 'ar' | 'de' | 'fr';
export type AudienceOption = 'teenagers' | 'middle age' | 'elderly' | 'high end' | 'mass';
export type PlatformOption = 'LinkedIn' | 'Twitter' | 'Facebook' | 'Instagram';
export type ApproachOption = 'Third Person' | 'First Person' | 'Documentary';
export type SuggestionPiece = 'introduction' | 'body' | 'conclusion';
export type ToneOption =
  | 'Neutral'
  | 'Friendly'
  | 'Professional'
  | 'Witty'
  | 'Informative'
  | 'Comparative'
  | 'Commentary'
  | 'Didactic';

// Authentication

export type Authorization = {
  userID: string;
  token: string;
};

export type LoginType = {
  email: string;
  password: string;
  remember?: boolean;
};

// Article

export type ArticleTextGenerationSettings = {
  templates: Template;
  aiTemplates?: AITemplate;
  presence_penalty?: number;
  frequency_penalty?: number;
  temperature?: number;
  tone?: ToneOption;
  platform?: PlatformOption;
  audience?: AudienceOption;
  approach?: ApproachOption;
  textLength: TextLengthOption;
  session_id?: string;
  suggestionPiece?: SuggestionPiece;
  prompt_length: number;
  num_samples: number;
  returnKeywords: boolean;
  language: LanguageOption;
  output_language: LanguageOption;
  fc_output_language: boolean;
};

export type ArticleImageGenerationSettings = {
  templates: Template;
  aestheticRating: number;
  aestheticWeight: number;
  guidanceScale: number;
  numberOfImage: number;
  promptStrength: number;
  relevance: number;
  seed: number;
  trialSteps: number;
};

export type Article = {
  id: string;
  user_id: string;
  is_active: boolean;
  title: string;
  text_prompt: TextContents;
  image_prompt: ImageContents;
  editor_content: RawDraftContentState | null;
  created_at: number;
  updated_at: number;
  folder_id: string;
  selected_template_id?: string;
  text_settings: TextSettings;
  image_settings: ImageSettings;
  paragraph_prompt: string[];
};

export type TextSettings = {
  temperature?: number;
  num_samples?: number;
  template_id?: string;
  ai_templates_id?: string;
  fc_output_language?: boolean;
  language?: LanguageOption;
  audience?: AudienceOption;
  output_language?: LanguageOption;
  text_length?: TextLengthOption;
  approach?: ApproachOption;
  tone?: ToneOption;
  platform?: PlatformOption;
};

export type ImageSettings = {
  template_id?: string;
  aesthetic_rating?: number;
  aesthetic_weight?: number;
  guidance_scale?: number;
  prompt_strength?: number;
  n?: number; // number_of_images
  relevance?: number;
  seed?: number;
  steps?: number;
};

export type TextContents = {
  [key: string]: ContentKeys | string;
};

export type ImageContents = {
  [key: string]: ContentKeys | string;
};

export type AITextGeneration = {
  id: string;
  generations: {
    content: string;
    content_en: string;
    statement_objectives: string[];
    statement_objectives_en: string[];
    facts: any; // TODO: Change later
  }[];
  keywords: string[];
  keywords_en: string[];
  created_at: number;
  tab: Tab;
  facts: Fact[] | ButtonFactCheck;
};

export type AIImageGeneration = {
  id: string;
  pictures: string[];
  created_at: number;
  tab: Tab;
};

export type ContentKeys = {
  company?: string;
  customer?: string;
  challenge?: string;
  solution?: string;
  results?: string;
  company_definition?: string;
  tone?: string;
  video_topic?: string;
  keywords?: string;
  additional_details?: string;
  command?: string;
  pushing_details?: string;
  information?: string;
  key_benefit?: string;
  problem?: string;
  product_name?: string;
  blog_topic?: string;
  topic?: string;
  headline?: string;
  product?: string;
  context?: string;
};

export type TextTemplateSettingsSection =
  | 'suggestionTextLength'
  | 'template'
  | 'tone'
  | 'platform'
  | 'audience'
  | 'approach'
  | 'language'
  | 'output_language'
  | 'fc_output_language'
  | 'prompt_length'
  | 'num_samples'
  | 'returnKeywords'
  | 'suggestionPiece';

export type ImageTemplateSettingsSection =
  | 'numberOfImage'
  | 'aestheticRating'
  | 'aestheticWeight'
  | 'guidanceScale'
  | 'promptStrength'
  | 'relevance'
  | 'seed'
  | 'trialSteps';

export type TemplateMeta = {
  template_id: string;
  last_used_at: number;
  usage_count: number;
};

export enum TEMPLATE_TYPES {
  INSTAGRAM_MARKET_PRODUCT = 'Instagram Market Product',
  AD_GENERATION = 'Ad Generation',
  ETSY_PRODUCT_DESCRIPTION = 'Etsy Product Description',
  GOOGLE_ADS_DESCRIPTION = 'Google Ads Description',
  VIDEO_SCRIPT = 'Video Script',
  VIDEO_DESCRIPTION = 'Video Description',
  CASE_STUDY = 'Case Study',
  REAL_ESTATE_LISTING = 'Real Estate Listing',
  COLD_EMAIL = 'Cold Email',
  SALES_SEQUENCE = 'Sales Sequence',
  PAIN_POINT_GENERATOR = 'Pain Point Generator',
  SEO_ARTICLE = 'SEO Article',
  ELEVATOR_PITCH = 'Elevator Pitch',
  FREE_FORM = 'Free-form'
}

// Template

export type CustomTemplateDefaultFields = {
  templateName: string;
  selectedTemplateType: TEMPLATE_TYPES;
  outputExample: string;
  templateDescription: string;
};

export type CustomTemplateRelatedFields = {
  [key: string]: string;
};

export type Template = {
  id: string;
  order: number;
  parent: string;
  name: string;
  base_template?: string;
  render: (TextTemplateSettingsSection | ImageTemplateSettingsSection)[];
  render_value?: Record<string, { start: number; end: number }>;
  endpoint: string;
  content: string;
  status?: 'in_progress' | 'completed' | 'cancelled';
  form: FormField[];
  type: 'text' | 'image';
  icon?: string;
  created_at?: number;
};

export type FormField = {
  id: keyof ContentKeys;
  title: string;
  description: string;
  placeholder: string;
  multiline: boolean;
  required: boolean;
};

export type AITemplate = {
  id: string;
  name: string;
  output_example: string;
  template_type: TEMPLATE_TYPES;
  form: Array<FormField>;
  [key: string]: any;

  data: {
    presence_penalty: number;
    frequency_penalty: number;
    temperature: number;
  };
};

// Article Utilities

export type Tab = {
  name: string;
  id: string;
  order: number;
  has_custom_name?: boolean;
  article_id: string;
};

export type Fact = {
  source_domain: string;
  news_list: string[];
  match_list: Array<{ objective_index: number; start: number; end: number }>;
};

export type ButtonFactCheck = {
  [key: string]: { link: string; content: string; similarity: number; rewritten: string }[];
};

export type AddedFact = {
  content: string;
  generationId: string;
  suggestionIndex: number;
  objectiveIndex: number;
  domain?: string;
};

export type FactMatch = {
  content: string;
  objective_index: number;
  source_domain: string;
};

export type Folder = {
  name: string;
  id: string;
  order: number;
  user_id: string;
  is_draft?: boolean;
  created_at: number;
  updated_at: number;
};

export type Bookmark = {
  id: string;
  created_at: number;
  article_id: string;
  type: 'text' | 'image';
  content: string;
};

export type Similarity = {
  sentence: string;
  author: string;
  date: string;
  score: number;
  exact_match: boolean;
  start: number;
  end: number;
};

export type Plagiarism = {
  globalPlagiarism: number | null;
  sources: {
    name: string;
    url: string;
    plagiarism: number;
    sentence: string;
  }[];
};

// Custom Template

export type CustomTemplateFile = {
  id: string;
  name: string;
  url: string;
  file_type: string;
  type: string;
  size: number;
  thumbnail_url: string;
  created_at: number;
  user_id: string;
};

// Account

export type PlanType = {
  id: string;
  name: string;
  price: number;
  cost: { name: string; value: boolean }[];
  pros: { name: string; value: boolean }[];
  price_id: string;
  request_quota: number;
  article_quota: number;
  is_cancelable: boolean;
  charge_interval: 'oneTime' | 'monthly' | 'yearly' | 'unknown';
  discount?: number;
};

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  company_id: string;
  created_ar: number;
  superEditor?: boolean;
  developerUser?: boolean;
  is_admin?: boolean;
};

export type UserPlan = {
  id: string;
  user_id: string;
  subscription_id: string;
  fact_check_count?: number;
  plagiarism_check_count?: number;
  auto_ml_count?: number;
  is_active: boolean;
  is_canceled: boolean;
  article_count: number;
  request_count: number;
  purchased_plan: {
    price: number;
    name: string;
    request_quota: number;
    article_quota: number;
    auto_ml_quota: number;
    fact_check_quota: number;
    plagiarism_quota: number;
    is_cancelable: boolean;
  };
  started_at: any;
  expires_at: any;
};
