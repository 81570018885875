import { ReactComponent as EditIcon } from 'assets/img/icons/Edit.svg';
import { ReactComponent as Dots } from 'assets/img/icons/dots-x3.svg';
import { ReactComponent as TrashIcon } from 'assets/img/icons/trash_small.svg';
import loadingLottie from 'assets/json/articleSaveLottie.json';
import Lottie from 'lottie-react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkIsAdmin, fetchAllUsers, resetAdmin, upgradeUserPlan } from 'redux/admin';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { User, UserPlan } from 'utils/types';
import { useQuery } from '../../utils/hooks/useQuery';
import AppButton from '../common/AppButton';
import { Dropdown } from '../common/Dropdown';
import { FlexCol, Label, Row, absoluteFill, centered } from '../common/Styles';

export const Admin = memo<{}>(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['admin']);
  const history = useHistory();

  const { isLoading } = useQuery('fetch_all_users');
  const isAdmin = useSelect(({ admin }) => admin.isAdmin);
  const users = useSelect(({ admin }) => admin.users);
  const userPlans = useSelect(({ admin }) => admin.userPlans);

  const [query, setQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const data = useMemo(() => {
    if (!users || !userPlans) return [];

    return users
      .filter((user) => {
        return (
          (user.first_name + ' ' + user.last_name).toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
        );
      })
      .map((user) => {
        const userPlan = userPlans.find(({ user_id }) => user_id === user.id);
        return { user, plan: userPlan };
      })
      .sort((a, b) => {
        return a.user.first_name.localeCompare(b.user.first_name);
      });
  }, [users, userPlans, query]);

  // @effects

  useEffect(() => {
    dispatch(checkIsAdmin());

    return () => {
      dispatch(resetAdmin());
    };
  }, []);

  useEffect(() => {
    if (isAdmin) dispatch(fetchAllUsers());
  }, [isAdmin]);

  // @handlers

  const dropdownOptions = useCallback(
    (plan: UserPlan) => {
      return plan.purchased_plan.name === 'Novus Agency'
        ? [{ title: t('downgrade'), icon: TrashIcon }]
        : [{ title: t('upgrade'), icon: EditIcon }];
    },
    [t]
  );

  const handleDropdownOptionClick = useCallback(
    (userPlan: UserPlan, user: User) => (option: number) => {
      switch (userPlan.purchased_plan.name) {
        case 'Novus Agency':
          if (option === 0) dispatch(upgradeUserPlan(user.id, false));
          break;

        default:
          if (option === 0) dispatch(upgradeUserPlan(user.id, true));
          break;
      }
    },
    [data]
  );

  const handleDropdownToggle = useCallback(
    (index: number) => (isOpen: boolean) => {
      if (isOpen) setSelectedIndex(index);
      else setSelectedIndex(-1);
    },
    []
  );

  const handleSearchQueryChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
  }, []);

  // @render

  if (isAdmin === false) {
    return (
      <FlexCol style={{ alignItems: 'center', paddingTop: '40vh' }}>
        <h1>{'You are not an admin'}</h1>
        <Row>
          <AppButton onClick={() => history.push('/')} text="GO HOME" />
        </Row>
      </FlexCol>
    );
  } else if (isAdmin === null || isLoading) {
    return (
      <FlexCol style={{ ...centered, ...absoluteFill }}>
        <Lottie animationData={loadingLottie} loop={true} style={{ height: 120 }} />
      </FlexCol>
    );
  }

  return (
    <FlexCol style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: 80 }}>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginTop: 12 }}>
        <h2>{'Admin Panel'}</h2>
        <SearchInput placeholder="Search user" onChange={handleSearchQueryChange} value={query} />
      </Row>

      <TableRow index={1}>
        <Row>
          <TableHeader style={{ width: 32, textAlign: 'left', fontWeight: 400 }}>{'#'}</TableHeader>
          <TableHeader style={{ textAlign: 'left', width: 180 }}>{'Name'}</TableHeader>
          <TableHeader style={{ textAlign: 'left' }}>{'Email'}</TableHeader>
        </Row>
        <Row>
          <TableHeader style={{ fontWeight: 800, width: 160 }}>{'Plan'}</TableHeader>
          <TableHeader style={{ fontWeight: 800 }}>{'Article'}</TableHeader>
          <TableHeader style={{ fontWeight: 800 }}>{'Request'}</TableHeader>
          <TableHeader style={{ fontWeight: 800 }}>{'AutoML'}</TableHeader>
          <TableHeader style={{ fontWeight: 800 }}>{'Fact Check'}</TableHeader>
          <TableHeader style={{ fontWeight: 800 }}>{'Plagiarism'}</TableHeader>
          <TableHeader style={{ fontWeight: 800, width: 64 }}>{''}</TableHeader>
        </Row>
      </TableRow>

      {data.map(({ user, plan }, index) => (
        <TableRow index={index} key={index} style={{ zIndex: selectedIndex === index ? Number.MAX_SAFE_INTEGER : 0 }}>
          <Row style={{ alignItems: 'center' }}>
            <Label style={{ fontWeight: 400, fontSize: 14, width: 32, textAlign: 'left', opacity: 0.5 }}>
              {index + 1 + '.'}
            </Label>
            <Label
              onClick={() => {
                navigator.clipboard.writeText(user.id);
                toast.success('Copied user id to clipboard');
              }}
              style={{
                fontWeight: 600,
                fontSize: 14,
                width: 160,
                textAlign: 'left',
                marginRight: 20,
                cursor: 'pointer'
              }}
            >
              {user.first_name + ' ' + user.last_name}
            </Label>

            <Label
              onClick={() => {
                navigator.clipboard.writeText(user.email);
                toast.success('Copied user email to clipboard');
              }}
              style={{ fontWeight: 500, fontSize: 14, opacity: 0.8, textAlign: 'left', cursor: 'pointer' }}
            >
              {user.email}
            </Label>
          </Row>

          {plan ? (
            <Row>
              <TableValue style={{ width: 160 }}>{plan.purchased_plan.name}</TableValue>

              <TableValue>
                {plan.article_count +
                  '/' +
                  (plan.purchased_plan.article_quota === -1 ? '∞' : plan.purchased_plan.article_quota)}
              </TableValue>

              <TableValue>
                {plan.request_count +
                  '/' +
                  (plan.purchased_plan.request_quota === -1 ? '∞' : plan.purchased_plan.request_quota)}
              </TableValue>

              <TableValue>
                {(plan.auto_ml_count?.toString() || '-') +
                  '/' +
                  (plan.purchased_plan.auto_ml_quota === -1
                    ? '∞'
                    : plan.purchased_plan.auto_ml_quota?.toString() || '-')}
              </TableValue>

              <TableValue>
                {(plan.fact_check_count?.toString() || '-') +
                  '/' +
                  (plan.purchased_plan.fact_check_quota === -1
                    ? '∞'
                    : plan.purchased_plan.fact_check_quota?.toString() || '-')}
              </TableValue>

              <TableValue>{plan.plagiarism_check_count?.toString() || '-'}</TableValue>

              <Dropdown
                alwaysOnTop
                onToggle={handleDropdownToggle(index)}
                style={{ position: 'relative', width: 64 }}
                position={{ right: 0, top: 24 }}
                options={dropdownOptions(plan)}
                handleOptionClick={handleDropdownOptionClick(plan, user)}
              >
                <Row style={{ padding: 12, marginRight: -6, borderRadius: 4 }}>
                  <Dots style={{ width: 16 }} />
                </Row>
              </Dropdown>
            </Row>
          ) : (
            <Label>{'NO PLAN'}</Label>
          )}
        </TableRow>
      ))}
    </FlexCol>
  );
});

const TableRow = styled(Row)<{ index: number }>`
  background-color: ${({ theme, index }) => (index % 2 === 0 ? 'white' : theme.colors.gray[20])};
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
`;

const TableValue = styled(Label)`
  font-weight: 500;
  font-size: 14px;
  width: 100px;
  line-height: 28px;
  text-align: left;
`;

const TableHeader = styled(Label)`
  font-weight: 800;
  font-size: 14px;
  width: 100px;
  text-align: left;
`;

const SearchInput = styled.textarea<{ multiline?: boolean }>`
  background-color: #fafbfd;
  border: 1px solid ${({ theme }) => theme.colors.soft[70]};
  border-radius: 4px;
  resize: none;
  height: ${({ multiline }) => (multiline ? '90px' : '40px')};
  outline: none;
  padding: 10px 14px;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary[40]};
  }
`;
