import AppButton from 'features/common/AppButton';
import { ArticleTextInputField } from 'features/common/ArticleTextInputField';
import { FlexCol } from 'features/common/Styles';
import { ChangeEvent, FormEvent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { generateImage, generateText } from 'redux/article';
import { setArticleImageContent, setArticleTextContent } from 'redux/editor';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { ContentKeys } from 'utils/types';
import ImageEditor from './ArticleFormImageEditor';
import { ArticleInputHeader } from './ArticleInputHeader';

export const ArticleFormEditor = memo(() => {
  const { t } = useTranslation(['article', 'common']);
  const { articleId } = useContext(ArticleContext);
  const dispatch = useDispatch();

  const generationType = useSelect(({ editor }) => editor.generationType[articleId] ?? 'text');
  const tabId = useSelect(({ editor }) => editor.selectedTabId[articleId] ?? '');
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const defaultAiTemplate = useSelect(({ entity }) => getEntities(entity, 'aiTemplate')[0]);
  const textSettings = useSelect(({ editor }) => editor.textGenerationSettings);
  const imageSettings = useSelect(({ editor }) => editor.imageGenerationSettings);
  const textTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedTextTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });
  const imageTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedImageTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });

  const { isLoading: isTextLoading } = useQuery('generate_text');
  const { isLoading: isImageLoading } = useQuery('generate_image');

  const [drawImage, setDrawImage] = useState<{
    open: boolean;
    previewImage: string | ArrayBuffer | null;
    fileImage: File | Blob | null;
  }>({ open: false, previewImage: null, fileImage: null });

  const template = useMemo(() => {
    return generationType === 'text' ? textTemplate : imageTemplate;
  }, [generationType, imageTemplate, textTemplate]);

  const content = useMemo(() => {
    return generationType === 'text' ? article?.text_prompt : article?.image_prompt;
  }, [generationType, article]);

  const placeholder = useMemo(() => {
    return generationType === 'text' ? t('article:placeholderText') : t('article:placeholderImage');
  }, [generationType]);

  // @handlers

  const handleGenerateClick = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!template || !article) return toast.error(t('common:errors.noTemplateSelected'));

      switch (generationType) {
        case 'text':
          const aiTemplates = textSettings.aiTemplates || defaultAiTemplate;
          const textGenerationSettings = { ...textSettings, templates: template, aiTemplates };
          dispatch(generateText(template, article, textGenerationSettings, tabId));
          break;

        case 'image':
          if (!article.image_prompt[template.id]) return toast.error(t('article:noInputEntered'));
          const imageGenerationSettings = { ...imageSettings, templates: template };
          dispatch(generateImage(template, article, imageGenerationSettings, tabId));
          break;
      }
    },
    [defaultAiTemplate, textSettings, imageSettings, generationType, article]
  );

  const handleInputChange = useCallback(
    (templateId: string, formId?: string) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!article) return;
      const value = e.currentTarget.value;

      switch (generationType) {
        case 'text':
          if (formId) {
            const prompts = article.text_prompt?.[templateId] as ContentKeys;
            const textContent = { ...article.text_prompt, [templateId]: { ...prompts, [formId]: value } };
            dispatch(setArticleTextContent(article.id, textContent));
          } else {
            dispatch(setArticleTextContent(articleId, { ...article.text_prompt, [templateId]: value }));
          }
          break;
        case 'image':
          if (formId) {
            dispatch(setArticleImageContent(articleId, { ...article.image_prompt, [templateId]: { [formId]: value } }));
          } else {
            dispatch(setArticleImageContent(articleId, { ...article.image_prompt, [templateId]: value }));
          }
          break;
      }
    },
    [generationType, article]
  );

  // @render

  return (
    <FlexCol>
      <Form onSubmit={handleGenerateClick}>
        <FormContainer>
          <ArticleInputHeader />

          <FormInputContainer>
            {generationType === 'text' && template?.form ? (
              template?.form.map((item, index) => {
                const baseTemplateName = template.parent === 'UserTemplate' ? template.base_template : template.name;
                return (
                  <ArticleTextInputField
                    key={index}
                    id={item.id}
                    label={t(`form.${baseTemplateName}.${item.id}.description`)}
                    title={t(`form.${baseTemplateName}.${item.id}.title`)}
                    required={item.required}
                    multiline={item.multiline}
                    value={(content?.[template.id] as ContentKeys)?.[item.id] ?? ''}
                    onChange={handleInputChange(template.id, item.id)}
                    placeholder={t(`form.${baseTemplateName}.${item.id}.placeholder`)}
                  />
                );
              })
            ) : (
              <FormTextInput
                value={(content?.[template?.id!] as string) ?? ''}
                onChange={handleInputChange(template?.id!)}
                placeholder={placeholder}
              />
            )}
          </FormInputContainer>
        </FormContainer>

        <GenerateButton text={t('create')} onClick={() => {}} loading={isTextLoading || isImageLoading} />
      </Form>

      <ImageEditor path={drawImage?.previewImage} open={drawImage?.open} setDrawImage={setDrawImage} />
    </FlexCol>
  );
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  flex: 1;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const FormTextInput = styled.textarea`
  background-color: #fafbfd;
  border: 1px solid ${({ theme }) => theme.colors.soft[70]};
  border-radius: 4px;
  resize: none;
  height: 25vh;
  outline: none;
  padding: 10px 14px;
  margin-bottom: 20px;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary[40]};
  }
`;

const GenerateButton = styled(AppButton)`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 63px;
  background-color: ${({ loading, theme }) => (loading ? theme.colors.primary[30] : theme.colors.primary[40])};
`;
