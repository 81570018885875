import articleSaveLottie from 'assets/json/articleSaveLottie.json';
import { ResetPassword } from 'features/ResetPassword';
import { VerifySignUp } from 'features/VerifySignUp';
import Account from 'features/account';
import UpgradePlanResult from 'features/account/UpgradePlanResult';
import { ArticlePage } from 'features/article';
import { FlexCol, absoluteFill, centered } from 'features/common/Styles';
import { HomePage } from 'features/home';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { NotFound } from './features/NotFound';
import { WelcomePage } from './features/Welcome';
import { identifyUser } from './utils/analytics';
import { Admin } from './features/admin';

export const App: React.FC = () => {
  const [isReady, setIsReady] = useState(false);
  const [firebaseAuthenticated, setFirebaseAuthenticated] = useState(false);

  // @effects

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      setFirebaseAuthenticated(!!user);
      setIsReady(true);
      user && identifyUser(user.uid, { email: user.email! });
    });
  }, []);

  // @render

  if (!isReady) {
    return (
      <FlexCol style={{ ...centered, ...absoluteFill }}>
        <Lottie animationData={articleSaveLottie} loop={true} style={{ height: 120 }} />
      </FlexCol>
    );
  } else if (!firebaseAuthenticated) {
    return (
      <Router>
        <Switch>
          <Route exact path="/verify-signup" component={VerifySignUp} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/upgrade-plan/result" component={UpgradePlanResult} />
          <Route path="*" component={WelcomePage} />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/article/:articleId"
            render={({ match }) => <ArticlePage articleId={match.params.articleId} />}
          />
          <Route
            exact
            path="/article/:articleId/:create"
            render={({ match }) => (
              <ArticlePage articleId={match.params.articleId} newArticle={!!match.params.create} />
            )}
          />
          <Route path="/account" component={Account} />
          <Route exact path="/admin" component={Admin} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
};
