import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { AtomicBlockUtils, convertFromRaw, convertToRaw, DraftHandleValue, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FlexCol } from 'features/common/Styles';
import { DragEvent, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchSimilarities, resetSimilarities } from 'redux/article';
import { saveEditorState } from 'redux/editor';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import {
  applySimilarityInlineStyle,
  editorStyle,
  getSelectionConfigForSimilarity,
  removeSimilarityInlineStyle,
  splitSimilarities
} from 'utils/helpers/articleHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleInputHeader } from '../ArticleInputHeader';
import { ArticleEditorActions } from './ArticleEditorActions';
import { toast } from 'react-toastify';
import { convertURLToPngBlobSourceUrl } from '../../../../utils/helpers/imageHelper';

const imagePlugin = createImagePlugin();

export const ArticleEditor = memo(() => {
  const editorRef = useRef<Editor>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation('article');
  const { articleId } = useContext(ArticleContext);

  const userId = useSelect(({ me }) => me.user?.id);
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const similarities = useSelect(({ article: _article }) => _article.similarities[articleId] ?? []);
  const isFocused = useSelect(({ editor }) => editor.inputType === 'editor');

  const [editorState, setEditorState] = useState<EditorState>(
    article?.editor_content
      ? EditorState.createWithContent(convertFromRaw(article.editor_content))
      : EditorState.createEmpty()
  );

  // @effects

  useEffect(() => {
    dispatch(resetSimilarities(articleId));
  }, []);

  useEffect(() => {
    if (editorRef.current) editorRef.current.focus();
  }, [isFocused]);

  useEffect(() => {
    if (similarities.length) {
      const currentContent = editorState.getCurrentContent();
      const blockMap = currentContent.getBlockMap();

      let state = editorState;
      splitSimilarities(similarities, blockMap).forEach((item) => {
        const { selectionConfig, isSentenceUnrelated } = getSelectionConfigForSimilarity(item, currentContent);
        if (isSentenceUnrelated) return;
        state = applySimilarityInlineStyle(state, selectionConfig, item.exact_match);
      });
      setEditorState(state);
    } else {
      const selection = editorState.getSelection();
      let state = EditorState.createWithContent(editorState.getCurrentContent());
      state = EditorState.forceSelection(removeSimilarityInlineStyle(state), selection);
      setEditorState(state);
    }
  }, [similarities.length]);

  // @handlers

  const handleBeforeInput = useCallback(() => {
    if (similarities.length) {
      dispatch(resetSimilarities(articleId));
      return 'handled' as const;
    }
    return 'not-handled' as const;
  }, [articleId, similarities.length]);

  const handleInsertImage = useCallback((state: EditorState, src: string) => {
    const contentState = state.getCurrentContent();
    const currentContent = contentState.createEntity('image', 'IMMUTABLE', { src });
    const entityKey = currentContent.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(state, { currentContent });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  }, []);

  const handleDropEnd = useCallback(
    (dragEvent: DragEvent<HTMLSpanElement>) => {
      if (dragEvent.type !== 'drop') return;
      const source = dragEvent.dataTransfer.getData('uri');
      convertURLToPngBlobSourceUrl(source, (uri) => {
        setEditorState(handleInsertImage(editorState, uri));
      });
    },
    [editorState, handleInsertImage]
  );

  const handleFilePaste = useCallback(() => {
    const applyPaste = async () => {
      const clipboardItems = await navigator.clipboard.read();
      const blob = await clipboardItems[0].getType('image/png');
      const source = URL.createObjectURL(blob);
      setEditorState(handleInsertImage(editorState, source));
    };
    applyPaste();
    return 'handled' as DraftHandleValue;
  }, [editorState, handleInsertImage]);

  const handleSaveClick = useCallback(() => {
    dispatch(saveEditorState(articleId, convertToRaw(editorState.getCurrentContent())));
  }, [editorState, articleId]);

  const handleProofReadClick = useCallback(() => {
    const editorContent = convertToRaw(editorState.getCurrentContent());
    const state = EditorState.createWithContent(convertFromRaw(editorContent));
    const rawState = convertToRaw(state.getCurrentContent());
    const plainText = state.getCurrentContent().getPlainText();

    if (plainText) dispatch(fetchSimilarities(articleId, plainText, userId ?? '', rawState));
    else toast.error(t('common:contentNotEmpty'));
  }, [editorState, article, userId]);

  // @render

  return (
    <FlexCol style={{ overflow: 'hidden' }}>
      <FlexCol style={{ maxHeight: 'calc(100vh - 130px)' }}>
        <ArticleInputHeader style={{ paddingLeft: 30, paddingRight: 30 }} />

        <Container onDrop={handleDropEnd}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={setEditorState}
            handleBeforeInput={handleBeforeInput}
            placeholder={t('editorPlaceholder')}
            customStyleMap={editorStyle}
            plugins={[imagePlugin]}
            handlePastedFiles={handleFilePaste}
          />
        </Container>

        <ArticleEditorActions
          editorState={editorState}
          onEditorStateChange={setEditorState}
          onSaveClick={handleSaveClick}
          onProofReadClick={handleProofReadClick}
        />
      </FlexCol>
    </FlexCol>
  );
});

const Container = styled.div`
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[40]};
  display: flex;
  overflow: hidden;
  margin-top: 16px;
  padding: 0px 0px 0px 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  & .DraftEditor-root {
    flex: 1;
    overflow: auto;
    padding-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }

  & .DraftEditor-editorContainer {
    height: 100%;
  }

  & .public-DraftEditorPlaceholder-root {
    width: auto;
    pointer-events: none;
  }
`;
